<div class="background-image-container mt-ncs">
  <div class="container" style="width:100%">
    <div class="jumbotron jumbotron-fluid" style="width: 100%;">
      <div class="d-flex flex-wrap" style="width: 100%;">
        <div style="width:100%; text-align:center">
          <h6 class="display-3" style="font-size: 3.5rem;
          font-weight: 300;
          line-height: 1.2;
          width: 100%;text-align:center;">{{'home.welcome.1' | translate}}<br/> {{'home.welcome.2' | translate}}</h6><br/><br/><br/>
          <p class="lead"></p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/jpg" srcset="assets/images/ban.jpg 2000w, assets/images/ban.jpg 1200w, assets/images/ban.jpg 768w">
    <img alt="" [src]="'assets/images/ban.jpg'"/>
    <div class="image-copyright" style="text-align: right;
    float: right;
    z-index: 1000;
    position: relative;
    top: -74px;
    right: 23px;
    color: #858585;">{{'home.welcome.imageBy' | translate}} Ερατώ Στυλιανουδάκη/ Erato Stylianoudaki</div>
  </picture>
</div>
